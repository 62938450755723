import React, { useEffect, useState } from 'react'
import { ProjectSupplierListMaster } from '../../Api/Project';
import Footer from '../Layout/Footer'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'
import { useNavigate, useLocation } from "react-router-dom";
import { masterData } from '../../Api/Master';
import { getGroupProjectReport, getSurveyLink, ProjecGroupChildDetail, ProjecGroupDetail, ProjectGroupMappedSupperDelete, ProjectMappedSupperDetail, ProjectSupplierListGroupChild, ProjectSupplierMappedGroupChild, ProjectSupplierMappedGroupUpdate, ProjectUpdateStatusGroupChild, saveSurveyLink } from '../../Api/ProjectGroup';
import Group_Prescreen_Tab from './Group_Prescreen_Tab';
import TabGroupChildSurveyLink from './TabGroupChild/gcSurveyLink';
import TabGroupChildDetail from './TabGroupChild/gcDetal';
import TabGroupChildSecurity from './TabGroupChild/gcSecurity';
import TabGroupChildSupplerMapped from './TabGroupChild/gcSupplierMapped';
import TabGroupChildPreScreen from './TabGroupChild/prescreenGroupChild';
import { downloadPrescreenReport } from '../../Api/PreScreen';
import TabGroupChildReInvite from './TabGroupChild/reInvite';

function Group_project_child_detail() {
    const navigate = useNavigate();
    const location = useLocation();
    const queryString = new URLSearchParams(location.search)
    const id = queryString.get('id')
    useEffect(() => {
        document.title = "Project Detail - Mindforce"
    },);
    const [filter, setFilter] = useState('Project_Detail')
    const [detail, setDetail] = useState()
    const [childDetail, setChildDetail] = useState();
    const [projectFilters, setProjectFilters] = useState();
    const [securityFilters, setSecurityFilters] = useState();
    const [deviceFilters, setDeviceFilters] = useState();
    const [mappedSupplierList, setMappedSupplierList] = useState();
    const [supplierList, setSupplierList] = useState()
    const [reactionTypes, setRectionsType] = useState()
    const [status, setStatus] = useState();
    const [editSurveyLink, setEditSurveylink] = useState(false);
    const [projectLinkType, setProjectLinkType] = useState('single-link') // 0 for single and 1 for multiple
    const [surveyLinkLive, setSurveyLinkLive] = useState()
    const [surveyLinkTest, setSurveyLinkTest] = useState()
    const [testSurveyLink, setTestSurveyLink] = useState()
    const [showSecurityFilterTab, setShowSecurityFilterTab] = useState(false);
    const [redirectLinks, setRedirectLinks] = useState();
    const [showPreScreenFilterTab, setShowPreScreenFilterTab] = useState(false);
    const [showReInviteTab, setShowReInviteTab] = useState(false);
    const [reportType, setReportType] = useState('project');
    useEffect(() => {
        projectDetailGroup()
    },[])
    useEffect(() => {
        getProjectSupplierList()
    },[])

    const handleFilter = async (e) => {
        setFilter(e.target.value)
        if(e.target.value === 'Supplier_Mapping'){
            getProjectSupplierList()
            let resSupplier = await ProjectSupplierListMaster();
            let resReactionType = await masterData({moduleName:'redirection-type'})
            if(resReactionType.status === 1){
                setRectionsType(resReactionType.payload)
            }
            if(resSupplier.status === 1){
                setSupplierList(resSupplier.payload)
            }
        }
        if(e.target.value === 'Survey_Link'){
            getSurveyLinkDetail()
        }
    }

    const getProjectSupplierList = async () => {
        let res = await ProjectSupplierListGroupChild({projectDetailID:id})
        if(res.status === 1){
            setMappedSupplierList(res.payload)
        }else{
            setMappedSupplierList()
        }
    }
    
    useEffect(() => {
        getProjectFilters()
        getSecurityFilters()
        getDeviceFilter()
    },[])

    const getProjectFilters = async() => {
        let res = await masterData({moduleName:'project-filters'})
        if(res.status === 1){
            setProjectFilters(res.payload)
        }
    }

    const getSecurityFilters = async() => {
        let res = await masterData({moduleName:'security-filters'})
        if(res.status === 1){
            setSecurityFilters(res.payload)
        }
    }

    const getDeviceFilter = async() => {
        let res = await masterData({moduleName:'device-filters'})
        if(res.status === 1){
            setDeviceFilters(res.payload)
        }
    }

    const projectDetailGroup = async () => {
        let res = await ProjecGroupChildDetail({id:id})
        if(res.status === 1){
            setDetail(res.payload[0])
            setRedirectLinks(res?.redirectLinks)
            let childDet = res.payload[0]?.childProjects[0]
            childDet.languageID = res.payload[0]?.languageID
            // console.log('childDet',childDet)
            setChildDetail(childDet)
            res.payload[0]?.childProjects?.[0]?.securityFilters?.map(ele => {
                if(ele?.status === 1){
                    setShowSecurityFilterTab(true)
                }
            });
            res.payload[0]?.childProjects?.[0]?.projectFilters.map(ele => {
                if (ele?.status === 1 && ele.id === 418) {// prescreen id
                    setShowPreScreenFilterTab(true)
                }
                if (ele?.status === 1 && ele.id === 425) {// reinvite id
                    setShowReInviteTab(true)
                }
            });
        }
    }

    const updateStatus = async () => {
        let res = await ProjectUpdateStatusGroupChild({id:id, status:status})
    }

    const getSurveyLinkDetail = async()=>{
        let res = await getSurveyLink({id:detail?.childProjects[0]?.projectDetailID})
        if(res.status === 1){
            setProjectLinkType(res?.payload?.surveyLinkDetails?.linkType)
            setSurveyLinkLive(res?.payload?.surveyLinkDetails?.liveUrl)
            setSurveyLinkTest(res?.payload?.surveyLinkDetails?.testUrl)
            setTestSurveyLink(res?.payload?.testSurveyLink)
        }
    }

    // const downloadReport = async() => {
    //     let res = await getGroupProjectReport({id:detail?.childProjects[0]?.projectDetailID})
        
    //     if(res.status === 1){
    //         window.open(res?.payload?.reportURL)
    //     }
    // }

    const handleReportType = (e) => {
        setReportType(e.target.value)
    }

    
    const downloadReport = async () => {
        if(reportType === 'project'){
            let res = await getGroupProjectReport({id:detail?.childProjects[0]?.projectDetailID})
            if (res.status === 1) {
                window.open(res?.payload?.reportURL)
            }
        }else if(reportType === 'prescreen'){
            let payload = {
                projectDetailId: detail?.childProjects[0]?.projectDetailID
            }
            let res = await downloadPrescreenReport({payload})
            if (res.status === 1) {
                window.open(res?.payload?.reportURL)
            }
        }
        
    }

    // Survey Link group Child
    const handleTestSurveyLink = (link)=>{
        setTestSurveyLink(link)
    }
    const handleEditSureyLink = () => {
        setFilter('Survey_Link')
        setEditSurveylink(true)
    }
    const handleUpdateEditSurvey = (val) =>{
        setEditSurveylink(val)
    }
    return (
        <div>
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid ">
                            <div className="row">
                                <div className="col-12 col-lg-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Project Detail</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a >All Projects</a></li>
                                                <li className="breadcrumb-item active">Project Detail</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}
                            <div className='project_detail_topsec'>
                                <div>  <button className="btn btn-primary" onClick={() => navigate('/all-project')} style={{ cursor: "pointer" }}><span className="  ri-arrow-left-fill"></span></button>
                                </div>
                                <div className=''>
                                    <h5> 
                                        {childDetail?.currentStatus ===
                                            "active" ? (
                                            <>
                                            <span className="badge bg-success active_btn">
                                                a
                                            </span>{" "}
                                            </>
                                        ) : (
                                            <>
                                            {childDetail?.currentStatus ===
                                                "inactive" ? (
                                                <>
                                                <span className="badge bg-warning inactive_btn">
                                                    a
                                                </span>
                                                </>
                                            ) : (
                                                <>
                                                {childDetail?.currentStatus ===
                                                    "invoiced" ? (
                                                    <>
                                                    <span className="badge Invoiced_btn">
                                                        a
                                                    </span>
                                                    </>
                                                ) : (
                                                    <>
                                                    {childDetail?.currentStatus ===
                                                        "closed" ? (
                                                        <>
                                                        <span className="badge bg-danger closed_btn">
                                                            a
                                                        </span>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    </>
                                                )}
                                                </>
                                            )}
                                            </>
                                        )}
                                        {' '+childDetail?.projectCode} : {childDetail?.fullName}
                                    </h5>
                                </div>
                            </div>
                            <div className="projectdetail_active_btn d-flex ">
                                <div className='tabs_ui'>
                                    <input type="radio" className="btn-check" name="status" id="option1" value="Project_Detail" onClick={(e) => handleFilter(e)} autocomplete="off" />
                                    <label className="btn btn-primary"htmlFor="option1" style={(filter === "Project_Detail" ? { backgroundColor: "#405189", color: "white" } : { backgroundColor: "white", color: "black" })}>Project Detail</label>

                                    <input type="radio" className="btn-check " name="status" id="option2" value="Survey_Link" onClick={(e) => handleFilter(e)} autocomplete="off" />
                                    <label className="btn btn-primary "htmlFor="option2" style={(filter === "Survey_Link" ? { backgroundColor: "#405189", color: "white" } : { backgroundColor: "white", color: "black" })}>Survey Link</label>

                                    {showSecurityFilterTab ? (<>
                                        <input type="radio" className="btn-check" name="status" id="option3" value="Security" onClick={(e) => handleFilter(e)} autocomplete="off" />
                                        <label className="btn btn-secondary "htmlFor="option3" style={(filter === "Security" ? { backgroundColor: "#405189", color: "white" } : { backgroundColor: "white", color: "black" })}>Security</label>
                                    </>) : (<></>)}

                                    {showPreScreenFilterTab ? (<>
                                        <input type="radio" className="btn-check" name="status" id="option6" value="PreScreen" onClick={(e) => handleFilter(e)} autocomplete="off" />
                                        <label className={filter === "PreScreen" ? "btn btn-primary outline_desh" : "btn btn-primary"}htmlFor="option6" style={(filter === "PreScreen" ? { backgroundColor: "#405189", color: "white" } : { backgroundColor: "white", color: "black" })}>PreScreen</label>
                                    </>) : (<></>)}
                                    {showReInviteTab ? (<>
                                        <input type="radio" className="btn-check" name="status" id="option7" value="ReInvite" onClick={(e) => handleFilter(e)} autocomplete="off" />
                                        <label className={filter === "ReInvite" ? "btn btn-primary outline_desh" : "btn btn-primary"}htmlFor="option7" style={(filter === "ReInvite" ? { backgroundColor: "#405189", color: "white" } : { backgroundColor: "white", color: "black" })}>ReInvite</label>
                                    </>) : (<></>)}

                                    <input type="radio" className="btn-check" name="status" id="option4" value="Supplier_Mapping" onClick={(e) => handleFilter(e)} autocomplete="off" />
                                    <label className="btn btn-secondary"htmlFor="option4" style={(filter === "Supplier_Mapping" ? { backgroundColor: "#405189", color: "white" } : { backgroundColor: "white", color: "black" })}>Supplier Mapping</label>

                                    <input type="radio" className="btn-check" name="status" id="option5" value="Project_Report" onClick={(e) => handleFilter(e)} autocomplete="off" />
                                    <label className="btn btn-secondary"htmlFor="option5" style={(filter === "Project_Report" ? { backgroundColor: "#405189", color: "white" } : { backgroundColor: "white", color: "black" })}>Project Report</label>
                                </div>
                                <div className='search' style={{ display: "flex" }}>
                                    {filter === "Project_Detail" &&
                                        <div className="btn-group ">
                                            <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" onChange={(e) => setStatus(e.target.value)}>
                                                <option value="active" selected={childDetail?.currentStatus === 'active' ? true :false}>Active</option>
                                                <option value="inactive" selected={childDetail?.currentStatus === 'inactive' ? true :false}>In Active</option>
                                                <option value="closed" selected={childDetail?.currentStatus === 'closed' ? true :false}>Closed</option>
                                                <option value="invoiced" selected={childDetail?.currentStatus === 'invoiced' ? true :false}>Invoiced</option>
                                            </select>
                                        </div>
                                    }
                                    {filter === "Project_Detail" && <button className="btn btn-primary" onClick={() => updateStatus()}>Update</button>}
                                    {filter === "Project_Detail" ? (<>
                                        <button className="btn btn-primary" onClick={() => navigate('/group-project-child-edit?id='+id)}><i className="ri-edit-2-line"></i></button>
                                        <button className="btn btn-primary"  onClick={() => navigate('/group-project-child-clone?id='+id)}><i className=" ri-file-copy-line"></i></button>
                                    </>) : (<></>)}
                                    {filter === "Survey_Link" && <>{testSurveyLink ? (<><a href={testSurveyLink} target="__blank"><button className="btn btn-primary">Test Survey</button></a></>) : (<></>)}</>}
                                    
                                    {/* {filter === "Survey_Link" ? (<>
                                        <button className="btn btn-primary" onClick={() => setEditSurveylink(true)}><i className="ri-edit-2-line"></i></button>
                                    </>) : (<></>)} */}

                                    {filter === "Survey_Link" && editSurveyLink === false ? (<>
                                        <button className="btn btn-primary" onClick={() => handleEditSureyLink()}><i className="ri-edit-2-line"></i></button>
                                    </>) : (<></>)}
                                    
                                    
                                    <button className="btn btn-primary" onClick={() => window.location.reload()} ><i className="ri-refresh-line"></i></button>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">

                                        {/* <!-- end card header --> */}
                                        {filter === "Project_Detail" &&
                                            <TabGroupChildDetail detail={detail} mappedSupplierList={mappedSupplierList} redirectLinks={redirectLinks} />
                                        }
                                        {filter === "Survey_Link" &&
                                            <TabGroupChildSurveyLink detail={detail} updateTestlink={handleTestSurveyLink} updateEditSurvey={handleUpdateEditSurvey} editSurveyLink={editSurveyLink} />
                                        }
                                        {filter === "PreScreen" &&
                                            // <Group_Prescreen_Tab detail={detail} />
                                            <TabGroupChildPreScreen detail={childDetail} />
                                        }
                                        {filter === "Security" &&
                                            <TabGroupChildSecurity detail={detail} />
                                        }
                                        {filter === "Supplier_Mapping" &&
                                            <TabGroupChildSupplerMapped
                                                detail={detail}
                                                mappedSupplierList={mappedSupplierList} 
                                                showPreScreenFilterTab={showPreScreenFilterTab}
                                                showSecurityFilterTab={showSecurityFilterTab}
                                                supplierList={supplierList}
                                                getProjectSupplierList={getProjectSupplierList}
                                                reactionTypes={reactionTypes}
                                             />
                                        }

                                        {filter === "Project_Report" &&
                                            <div className="card-body">
                                                <div>
                                                    <div className='row'>
                                                        <div className='col-lg-3 col-md-3'>
                                                            <div className="form-check form-radio-outline form-radio-info mb-3">
                                                                <input className="form-check-input" type="radio" name="formradiocolor14" checked={true} id="formradioRight18" value="project" onClick={(e) => handleReportType(e)} />
                                                                <label className="form-check-label"htmlFor="formradioRight18">
                                                                    Project Report
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-3 col-md-3'>
                                                            <div className="form-check form-radio-outline form-radio-info mb-3">
                                                                <input className="form-check-input" type="radio" name="formradiocolor14" id="prescreenreport" value="prescreen" onClick={(e) => handleReportType(e)} />
                                                                <label className="form-check-label"htmlFor="prescreenreport">
                                                                    PreScreen Report
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="text-end">
                                                            <button type="submit" className="btn btn-primary mx-2" onClick={()=>downloadReport()}>Download</button>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        }

                                        {
                                            filter === "ReInvite" &&
                                            <TabGroupChildReInvite detail={detail} />
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Group_project_child_detail