import React from 'react'
import Footer from '../Layout/Footer'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'
import { useEffect, useState } from 'react'
import { masterData } from '../../Api/Master'
import { urlValidate } from '../../Common/Regex'
import { ClientDetail, ClientUpdate } from '../../Api/Client'
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import {useLocation} from 'react-router-dom';

function Edit_client() {
    const location = useLocation();
    const navigate = useNavigate();
    const [form, setForm] = useState(location.state);
    const [errors, setErrors] = useState({});
    const [countries, setCountries] = useState();
    useEffect(() => {
        getDetails()
        getCountries()
    },[])

    const getDetails = async() => {
        let res = await ClientDetail({payload:location.state})
        if(res.status === 1){
            let formSet  = res.payload[0];
            formSet.Country = res.payload[0].countryID
            await setForm(formSet)
            // setForm({...form, ['Country']:res.payload[0].countryID})
        }else{
            navigate("/client");
        }
    }

    const getCountries = async() => {
        let res = await masterData({moduleName:'country'})
        if(res.status === 1){
            setCountries(res.payload)
        }
    }
    const setField = (field, value) => {
        setForm({ ...form, [field]: value })
        if (!!errors[field])
            setErrors({ ...errors, [field]: null })
    }

    const validateForm = () =>{
        const{clientName,contactPerson,Country,websiteUrl} = form;
        const newErrors = {};
        if(!clientName || clientName === '') newErrors.clientName = 'Please enter client name';
        if(!contactPerson || contactPerson === '') newErrors.contactPerson = 'Please enter this field';
        if(!Country || Country === 'Select Country') newErrors.Country = 'Please select country';
        if(!websiteUrl || websiteUrl === '') newErrors.websiteUrl = 'Please enter this field';
        if(websiteUrl && urlValidate(websiteUrl) === false) newErrors.websiteUrl = 'Invalid Url'
        return newErrors
    }
    const handleUpdate = async (e) => {
        e.preventDefault();
        setErrors()
        const formErrors = validateForm()
        if(Object.keys(formErrors).length > 0){
            setErrors(formErrors)
        }else{
            let res = await ClientUpdate({payload:form});
            if(res.status === 1){
                Swal.fire({
                    text: res.message,
                    showCancelButton:false,
                    showConfirmButton:true,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    timer: 1500,
                }).then((result) => {
                    navigate("/client");
                })
            }else{
                setErrors({...errors,['submit']:res.message})
            }
        }
    }

    useEffect(() => {
        document.title = "Edit Client - Mindforce"
    },);
    return (
        <div>
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 col-lg-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Edit Client</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a >Client</a></li>
                                                <li className="breadcrumb-item active">Edit Client</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}
                            <div className="row">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">
                                        {/* <!-- end card header --> */}
                                        <div className="card-body">
                                            <div className="">
                                                <form >
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="mb-3 required_field">{errors?.submit}</div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label className="form-label mb-0">Client Name <span className="required_field">*{errors?.clientName}</span></label>
                                                                <input type="text" className="form-control" placeholder="Enter client name" defaultValue={form?.clientName} onChange={(e) => setField('clientName', e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label  className="form-label mb-0">Contact Person <span className="required_field">*{errors?.contactPerson}</span></label>
                                                                <input type="text" className="form-control" placeholder="Enter contact person" id="contactPerson" defaultValue={form?.contactPerson} onChange={(e) => setField('contactPerson', e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label  className="form-label mb-0">Country <span className="required_field">*{errors?.Country}</span></label>
                                                                <select  className="form-select" data-choices data-choices-sorting="true" onChange={(e) => setField('Country', e.target.value)} >
                                                                    <option disabled selected>Select Country</option>
                                                                    {countries?.map((country) => {
                                                                        return (<option value={country.id} selected={form?.countrySlug === country.slug ? true : false}>{country.name}</option>)
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label  className="form-label mb-0">Email ID </label>
                                                                <input type="email" className="form-control" placeholder="Enter email" id="emailID"  defaultValue={form?.email} onChange={(e) => setField('email', e.target.value)} />
                                                            </div>
                                                        </div>
                                                        {/* <!--end col--> */}
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label  className="form-label mb-0">Contact Number</label>
                                                                <input type="text" className="form-control" placeholder="Enter number" id="contactNumber"  defaultValue={form?.contactNumber} onChange={(e) => setField('contactNumber', e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label  className="form-label mb-0">Website URL <span className="required_field">*{errors?.websiteUrl}</span></label>
                                                                <input type="url" className="form-control" placeholder="Enter url"  defaultValue={form?.websiteUrl} onChange={(e) => setField('websiteUrl', e.target.value)} />
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="col-lg-12">
                                                            <div className="text-end">
                                                                <button type="submit" onClick={handleUpdate} className="btn btn-primary mx-2">Submit</button>
                                                                <button type="Cancel" className="btn btn-secondary">Cancel</button>
                                                            </div>
                                                        </div>


                                                        {/* <!--end col--> */}
                                                    </div>
                                                    {/* <!--end row--> */}
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/* <!-- end col --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Edit_client